import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class VinculacionService {

    constructor(private http: HttpClient) { }

    getAll(params?) {
        const str = [];
        for (const p in params) {
            if (params.hasOwnProperty(p) && params[p]) {
                str.push(`${encodeURIComponent(p)}=${encodeURIComponent(params[p])}`);
            }
        }
        return this.http.get<any>(
            `${environment.apiUrl}/vinculacion?${str.join('&')}`,
        ).pipe(
            map(
                (response: any) => response.response,
            ),
        );
    }

    get(eventoId) {
        return this.http.get<any>(
            `${environment.apiUrl}/vinculacion/${eventoId}`,
          ).pipe(
            map(
              (response: any) => response.response,
            ),
          );
    }

    setStatus(id) {
        return this.http.post<any>(
                    `${environment.apiUrl}/vinculacion/status/${id}`,
                    {},
                ).pipe(
                    map(
                        (response: any) => response.response,
                    ),
                );
    }

    delete(id) {
        return this.http.delete<any>(
            `${environment.apiUrl}/vinculacion/${id}`,
            {
                headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' }),
            },
        ).pipe(
            map(
                (response: any) => response.response,
            ),
        );
    }

    save(item) {
        const formData: any = new FormData();
        for (const key in item) {
            if (item[key]) {

                const value = item[key];
                if ( (key.indexOf('imagen') !== -1) && value && value.name ) {
                  formData.append(key, value, value.name);
                } else if (Array.isArray(value)) {
                    value.forEach( (elem, index) => {
                        if (key === 'documentos' && elem && elem.name) {
                          if (elem instanceof File) {
                            formData.append(`documentos[]`, elem, elem.name);
                          } else {
                            formData.append(`docs[]`, elem.name);
                          }
                        } else if ((key === 'categorias') && elem) {
                            formData.append(`${key}[${index}]`, elem);
                        } else {
                            for (const keyElem in elem) {
                                if (elem.hasOwnProperty(keyElem)) {
                                    formData.append(`${key}[${index}][${keyElem}]`, elem[keyElem]);
                                }
                            }

                        }

                    });

                } else if (value) {

                    formData.append(key, value);

                }
            }

        }

        return this.http.post<any>(
            `${environment.apiUrl}/vinculacion`,
            formData,
        ).pipe(
            map(
                (response: any) => response.response,
            ),
        );
    }

}
