import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthService {

    constructor(private http: HttpClient) { }

    login(data) {
        return this.http.post<any>(
          `${environment.apiUrl}/auth/login`,
          data,
          {},
        ).pipe(
          map(
            (response: any) => response,
          ),
          catchError(
            (response: any) => of(response.error)
          ),
        );
    }

}
