import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

export const AppRoutes: Routes = [
    {
      path: '',
      redirectTo: 'pages/login',
      pathMatch: 'full',
    }, {
      path: '',
      component: AdminLayoutComponent,
      children: [
        {
          path: 'evento',
          loadChildren: './evento/evento.module#EventoModule'
        },
        {
          path: 'formulario',
          loadChildren: './formulario/formulario.module#FormularioModule'
        }, {
          path: 'avisos/:tipo',
          loadChildren: './avisos/avisos.module#AvisosModule'
        }, {
          path: 'vinculacion',
          loadChildren: './vinculacion/vinculacion.module#VinculacionModule'
        }, {
          path: 'convocatoria',
          loadChildren: './convocatoria/convocatoria.module#ConvocatoriaModule'
        }
      ]
    }, {
      path: '',
      component: AuthLayoutComponent,
      children: [{
        path: 'pages',
        loadChildren: './pages/pages.module#PagesModule'
      }]
    }
];
