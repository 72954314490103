import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class FormularioService {

    private table = 'formulario';

    constructor(private http: HttpClient) { }

    setTable(t) {
        this.table = t;
    }

    getAll(params?) {
        const str = [];
        for (const p in params) {
            if (params.hasOwnProperty(p) && params[p]) {
                str.push(`${encodeURIComponent(p)}=${encodeURIComponent(params[p])}`);
            }
        }
        return this.http.get<any>(
            `${environment.apiUrl}/${this.table}?${str.join('&')}`,
        ).pipe(
            map(
                (response: any) => response.response,
            ),
        );
    }

    get(id) {
        return this.http.get<any>(
            `${environment.apiUrl}/${this.table}/${id}`,
          ).pipe(
            map(
              (response: any) => response.response,
            ),
          );
    }

    save(item) {
        if (item.hasOwnProperty('id')) {
            return this.http.put<any>(
                `${environment.apiUrl}/${this.table}/${item.id}`,
                item,
                {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' }),
                },
            ).pipe(
                map(
                    (response: any) => response.response,
                ),
            );
        }
        return this.http.post<any>(
            `${environment.apiUrl}/${this.table}`,
            item,
            {
                headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' }),
            },
        ).pipe(
            map(
                (response: any) => response.response,
            ),
        );
    }

    eliminar(id) {
        return this.http.delete<any>(
            `${environment.apiUrl}/${this.table}/${id}`,
            {
                headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' }),
            },
        ).pipe(
            map(
                (response: any) => response.response,
            ),
        );
    }

}
