import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'asDate'})
export class AsDatePipe implements PipeTransform {
  transform(value: string, args: string[]): any {
    if (!value) {
        return value;
    }
    const t = value.split(/[- :]/);
    return new Date( parseInt(t[0]), parseInt(t[1]) - 1, parseInt(t[2]), parseInt(t[3]) || 0, parseInt(t[4]) || 0, parseInt(t[5]) || 0);
  }
}
