import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class ConvocatoriaService {

    private prefix = '';
    constructor(private http: HttpClient) { }

    setPrefix(prefix: string) {
      this.prefix = prefix;
    }

    getPrefix() {
      return this.prefix;
    }

    getAll(params?) {
        const str = [];
        for (const p in params) {
            if (params.hasOwnProperty(p) && params[p]) {
                str.push(`${encodeURIComponent(p)}=${encodeURIComponent(params[p])}`);
            }
        }
        return this.http.get<any>(
            `${environment.apiUrl}/convocatoria${this.prefix}?${str.join('&')}`,
        ).pipe(
            map(
                (response: any) => response.response,
            ),
        );
    }

    get(vinculacionId) {
        return this.http.get<any>(
            `${environment.apiUrl}/convocatoria${this.prefix}/${vinculacionId}`,
          ).pipe(
            map(
              (response: any) => response.response,
            ),
          );
    }

    delete(id) {
        return this.http.delete<any>(
            `${environment.apiUrl}/convocatoria${this.prefix}/${id}`,
            {
                headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' }),
            },
        ).pipe(
            map(
                (response: any) => response.response,
            ),
        );
    }

}
